import cn from 'classnames';
import {
  PrismicButton,
  ImageWrapper,
  SliceWrapper,
  QRCodeDownloadSection,
} from '@ee-monorepo/prismic/shared/ui';
import { Box } from '@mui/material';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import { getColor } from '@ee-monorepo/shared/utilities/functions';
import { richTextSerializerHeading1Override } from '@ee-monorepo/prismic/shared/serializers';
import { PrismicRichText } from '@prismicio/react';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';

export interface CallToActionProps extends PrismicFields.SliceBase {
  slice?: {
    variation?: 'default-slice' | 'callToActionWithRoundImg';
    primary?: {
      text?: PrismicFields.RichTextField;
      cta_image?: PrismicFields.Image;
      cta_show_image: boolean;
      bg_color?: PrismicFields.Select;
      subheader_text?: PrismicFields.KeyTextField;
      subheader_text_color?: PrismicFields.Select;
      header_text_color?: PrismicFields.Select;
      promote_mobile_app?: boolean;
      promote_mobile_title?: PrismicFields.RichTextField;
      qr_logo?: PrismicFields.Image;
      display_qr_on_left?: boolean;
      apple_store_logo?: PrismicFields.Image;
      apple_store_link?: {
        url?: string;
      };
      google_store_logo?: PrismicFields.Image;
      google_store_link?: {
        url?: string;
      };
    };
    items?: [
      {
        button_label?: PrismicFields.KeyTextField;
        button_link?: PrismicFields.Link;
        button_inverted_variant?: boolean;
        button_variant?: PrismicFields.ButtonVariant;
        action?: PrismicFields.ButtonAction;
        show_button?: boolean;
      }
    ];
  };
}

// eslint-disable-next-line complexity
export function CallToAction({
  index,
  slice: { variation, primary, items },
}: CallToActionProps) {
  const {
    text,
    cta_image,
    cta_show_image,
    bg_color,
    header_text_color,
    subheader_text,
    subheader_text_color,
    promote_mobile_app,
    promote_mobile_title,
    qr_logo,
    display_qr_on_left,
    apple_store_logo,
    apple_store_link,
    google_store_logo,
    google_store_link,
  } = primary;
  const isMobile = useHandHeld();
  const mobileCheck = isMobile && cta_image.mobile;
  const isCtaShowImage = cta_show_image !== false;
  const { tracking } = useTrackingContext();
  return (
    <SliceWrapper
      data-testid="call-to-action"
      data-slice-index={index}
      className={cn(
        'snap-container-no-pad',
        (isMobile || variation === 'default-slice') &&
          getColor(bg_color).backgroundColorClass,
        'rounded-[4px]',
        'overflow-hidden'
      )}
    >
      <Box
        className={cn(
          'lg:flex',
          variation === 'default-slice' && 'items-center snap-row',
          variation === 'callToActionWithRoundImg' &&
            'lg:relative lg:h-[398px] lg:items-end sm:grid'
        )}
      >
        <Box
          className={
            isCtaShowImage
              ? cn(
                  'flex flex-col py-3 pl-4 pr-4 md:pl-10 md:pr-6 lg:w-1/2',
                  variation === 'default-slice' && 'justify-center',
                  getColor(bg_color).backgroundColorClass,
                  variation === 'callToActionWithRoundImg'
                    ? !display_qr_on_left
                      ? 'lg:w-full py-7'
                      : 'lg:w-3/5 py-7 lg:flex-row items-center justify-start'
                    : ''
                )
              : cn(
                  'lg:w-full',
                  'text-center',
                  'items-center',
                  'flex',
                  'flex-col',
                  'justify-center',
                  'pl-12',
                  'pr-12',
                  'py-10'
                )
          }
        >
          {variation === 'callToActionWithRoundImg' && display_qr_on_left && (
            <div
              className="hidden mr-5 md:inline [&>img]:rounded-lg"
              data-testid="qr-code-picture-left"
            >
              <ImageWrapper
                src={qr_logo?.url as string}
                alt={qr_logo?.alt as string}
                width={qr_logo?.dimensions?.width}
                height={qr_logo?.dimensions?.height}
              />
            </div>
          )}
          <div
            className={cn(
              variation === 'callToActionWithRoundImg' &&
                display_qr_on_left &&
                'lg:w-3/5'
            )}
          >
            <Box className={cn('mb-2 mt-4')}>
              <span
                data-testid="call-to-action-title"
                className={cn(
                  getColor(header_text_color).textColorClass,
                  '[&>h2]:headingL'
                )}
              >
                <PrismicRichText
                  field={text}
                  components={richTextSerializerHeading1Override}
                />
              </span>
              {subheader_text && (
                <span
                  data-testid="call-to-action-sub-title"
                  className={cn(
                    getColor(subheader_text_color).textColorClass,
                    'xl:headingM'
                  )}
                >
                  {subheader_text}
                </span>
              )}
            </Box>
            {variation === 'default-slice' && (
              <Box className="flex flex-col md:flex-row justify-around md:justify-start md:mt-3">
                {items &&
                  items.map((el, i) => {
                    return (
                      <Box
                        data-testid="call-to-action-button"
                        className="lg:mr-5 mb-3 lg:mb-0"
                        key={i}
                      >
                        <PrismicButton
                          label={el.button_label}
                          link={el.button_link}
                          inverseMode={el.button_inverted_variant}
                          variant={el.button_variant}
                          actionType={el.action}
                          showButton={el.show_button}
                          fullWidth
                          onClick={() => {
                            tracking.logClickEvent({
                              click_type: 'cta',
                              placement: 'body',
                              click_value: el.button_label,
                            });
                          }}
                        />
                      </Box>
                    );
                  })}
              </Box>
            )}
            {(promote_mobile_app ||
              variation === 'callToActionWithRoundImg') && (
              <QRCodeDownloadSection
                variation={variation}
                promote_mobile_title={promote_mobile_title}
                qr_logo={qr_logo}
                display_qr_on_left={display_qr_on_left}
                apple_store_logo={apple_store_logo}
                apple_store_link={apple_store_link?.url}
                google_store_logo={google_store_logo}
                google_store_link={google_store_link?.url}
                classes="forCallToAction"
              />
            )}
          </div>
        </Box>
        <Box
          className={cn(
            'lg:w-1/2',
            variation === 'callToActionWithRoundImg' &&
              'lg:w-2/5 lg:absolute lg:bottom-0 lg:right-0 sm:text-center',
            'sm:w-full',
            'lg:order-last',
            'px-0'
          )}
        >
          {isCtaShowImage && (
            <ImageWrapper
              className="block w-full h-auto"
              src={mobileCheck ? cta_image?.mobile?.url : cta_image?.url}
              alt={mobileCheck ? cta_image?.mobile?.alt : cta_image?.alt}
              width={
                mobileCheck
                  ? cta_image?.mobile?.dimensions?.width
                  : cta_image?.dimensions?.width
              }
              height={
                mobileCheck
                  ? cta_image?.mobile?.dimensions?.height
                  : cta_image?.dimensions?.height
              }
            />
          )}
        </Box>
      </Box>
    </SliceWrapper>
  );
}
